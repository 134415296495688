<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="观看数" prop="lookNum">-->
<!--                <a-input v-model="queryParam.lookNum" placeholder="请输入观看数" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="前多少集可以使用金币观看" prop="limitNum">-->
<!--                <a-input v-model="queryParam.limitNum" placeholder="请输入前多少集可以使用金币观看" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <template v-if="advanced">-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="每集钻石价格" prop="diamondPrice">-->
<!--                  <a-input v-model="queryParam.diamondPrice" placeholder="请输入每集钻石价格" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="每集金币价格" prop="goldPrice">-->
<!--                  <a-input v-model="queryParam.goldPrice" placeholder="请输入每集金币价格" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="简介" prop="describe">-->
<!--                  <a-input v-model="queryParam.describe" placeholder="请输入简介" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="标签ids" prop="tagIds">-->
<!--                  <a-input v-model="queryParam.tagIds" placeholder="请输入标签ids" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="总集数" prop="totalNum">-->
<!--                  <a-input v-model="queryParam.totalNum" placeholder="请输入总集数" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="8" :sm="24">
                <a-form-item label="剧名" prop="videoName">
                  <a-input v-model="queryParam.videoName" placeholder="请输入剧名" allow-clear/>
                </a-form-item>
              </a-col>
            <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
            <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="封面图" prop="coverUrl">-->
<!--                  <a-input v-model="queryParam.coverUrl" placeholder="请输入封面图" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--            </template>-->
<!--            <a-col :md="!advanced && 8 || 24" :sm="24">-->
<!--              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">-->
<!--               -->
<!--&lt;!&ndash;                <a @click="toggleAdvanced" style="margin-left: 8px">&ndash;&gt;-->
<!--&lt;!&ndash;                  {{ advanced ? '收起' : '展开' }}&ndash;&gt;-->
<!--&lt;!&ndash;                  <a-icon :type="advanced ? 'up' : 'down'"/>&ndash;&gt;-->
<!--&lt;!&ndash;                </a>&ndash;&gt;-->
<!--              </span>-->
<!--            </a-col>-->
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        v-hasPermi="['video:video:add']"-->
        <a-button type="primary" @click="$refs.createForm.handleAdd()" >
          <a-icon type="plus" />新增
        </a-button>

        <a-button type="danger" :disabled="multiple" @click="handleDelete">
          <a-icon type="delete" />删除
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
           <img slot="coverUrl" slot-scope="text, record" style="width: 48px;height: 48px;" :preview="record.coverUrl" v-image-preview :src="record.coverUrl">
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"  />
<!--          v-hasPermi="['video:video:edit']"-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" />
           <a @click="$refs.Gather.getLists(record.id)">
            <a-icon type="search" />剧集管理
          </a>
          <a-divider type="vertical" />
<!--          v-hasPermi="['video:video:remove']" -->
          <a @click="handleDelete(record)"  >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
        :rowKey="(record, index) => index + 1"
      />
    </a-card>
    <gather ref="Gather"></gather>
  </page-header-wrapper>
</template>

<script>
import { pageVideo,listVideo, delVideo } from '@/api/video/video'
import CreateForm from './modules/CreateForm'
import Gather from '../gather/index'
import { tableMixin } from '@/store/table-mixin'
import ImportExcel from './modules/ImportExcel'

export default {
  name: 'Video',
  components: {
    CreateForm,
    Gather,
    ImportExcel
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        lookNum: null,
        limitNum: null,
        diamondPrice: null,
        goldPrice: null,
        describe: null,
        tagIds: null,
        totalNum: null,
        videoName: null,
        coverUrl: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          ellipsis: true,
          customRender: (text, record, index) => index + 1,
          align: 'center'
        },
        // {
        //   title: '导入id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   width: '20%',
        //   align: 'center'
        // },
        {
          title: '剧名',
          dataIndex: 'videoName',
          align: 'center'
        },
        // {
        //   title: '英文剧名',
        //   dataIndex: 'videoNameEn',
        //   align: 'center'
        // },
        {
          title: '封面图',
          dataIndex: 'coverUrl',
          align: 'center',
          scopedSlots: { customRender: 'coverUrl' }
        },
        {
          title: '总集数',
          dataIndex: 'totalNum',
          align: 'center'
        },

        // {
        //   title: '前多少集可以使用金币观看',
        //   dataIndex: 'limitNum',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '每集钻石价格',
          dataIndex: 'diamondPrice',
          align: 'center'
        },
        // {
        //   title: '每集金币价格',
        //   dataIndex: 'goldPrice',
        //   align: 'center'
        // },
        {
          title: '上映时间',
          dataIndex: 'releaseTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '分类',
          dataIndex: 'videoType',
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'tagIdTitle',
          align: 'center'
        },
        {
          title: '地区',
          dataIndex: 'videoCountry',
          align: 'center'
        },
        {
          title: '年份',
          dataIndex: 'videoTime',
          align: 'center'
        },
        {
          title: '是否热门',
          dataIndex: 'isHot',
          align: 'center',
          customRender(text){
            if(text == 0){
              return "否"
            }
            return "是"
          }
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '观看数',
          dataIndex: 'lookNum',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询短剧列表 */
    getList () {
      this.loading = true
     pageVideo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        lookNum: undefined,
        limitNum: undefined,
        diamondPrice: undefined,
        goldPrice: undefined,
        describe: undefined,
        tagIds: undefined,
        totalNum: undefined,
        videoName: undefined,
        coverUrl: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delVideo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('video/video/export', {
            ...that.queryParam
          }, `短剧_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

import request from '@/utils/request'


// 查询短剧分类列表
export function listType(query) {
  return request({
    url: '/video/video-type/list',
    method: 'get',
    params: query
  })
}

// 查询短剧分类分页
export function pageType(query) {
  return request({
    url: '/video/video-type/page',
    method: 'get',
    params: query
  })
}

// 查询短剧分类详细
export function getType(data) {
  return request({
    url: '/video/video-type/detail',
    method: 'get',
    params: data
  })
}

// 新增短剧分类
export function addType(data) {
  return request({
    url: '/video/video-type/add',
    method: 'post',
    data: data
  })
}

// 修改短剧分类
export function updateType(data) {
  return request({
    url: '/video/video-type/edit',
    method: 'post',
    data: data
  })
}

// 删除短剧分类
export function delType(data) {
  return request({
    url: '/video/video-type/delete',
    method: 'post',
    data: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/video/video-type/changeIsOpen',
    method: 'post',
    data: data
  })
}
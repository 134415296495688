import request from '@/utils/request'


// 查询视频年份列表
export function listTime(query) {
  return request({
    url: '/video/video-time/list',
    method: 'get',
    params: query
  })
}

// 查询视频年份分页
export function pageTime(query) {
  return request({
    url: '/video/video-time/page',
    method: 'get',
    params: query
  })
}

// 查询视频年份详细
export function getTime(data) {
  return request({
    url: '/video/video-time/detail',
    method: 'get',
    params: data
  })
}

// 新增视频年份
export function addTime(data) {
  return request({
    url: '/video/video-time/add',
    method: 'post',
    data: data
  })
}

// 修改视频年份
export function updateTime(data) {
  return request({
    url: '/video/video-time/edit',
    method: 'post',
    data: data
  })
}

// 删除视频年份
export function delTime(data) {
  return request({
    url: '/video/video-time/delete',
    method: 'post',
    data: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/video/video-time/changeIsOpen',
    method: 'post',
    data: data
  })
}
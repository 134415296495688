import request from '@/utils/request'


// 查询视频国家地区列表
export function listCountry(query) {
  return request({
    url: '/video/video-country/list',
    method: 'get',
    params: query
  })
}

// 查询视频国家地区分页
export function pageCountry(query) {
  return request({
    url: '/video/video-country/page',
    method: 'get',
    params: query
  })
}

// 查询视频国家地区详细
export function getCountry(data) {
  return request({
    url: '/video/video-country/detail',
    method: 'get',
    params: data
  })
}

// 新增视频国家地区
export function addCountry(data) {
  return request({
    url: '/video/video-country/add',
    method: 'post',
    data: data
  })
}

// 修改视频国家地区
export function updateCountry(data) {
  return request({
    url: '/video/video-country/edit',
    method: 'post',
    data: data
  })
}

// 删除视频国家地区
export function delCountry(data) {
  return request({
    url: '/video/video-country/delete',
    method: 'post',
    data: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/video/video-country/changeIsOpen',
    method: 'post',
    data: data
  })
}